import { HttpClient, } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// interfaces 
import { Injectable } from '@angular/core';
import { IApiResp } from 'src/app/interfaces/api-resp';

@Injectable({
  providedIn: 'root'
})
export class DatosAuxService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
  ) { }

  getPais(id: number) {
    const path = `${this.apiURL}/paises/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getListaPaises() {
    const path = `${this.apiURL}/getListaPaises`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getListaServicios() {
    // Devuelve todos los servicios de aux_servicios
    const path = `${this.apiURL}/getListaServicios`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getListaServiciosCliente(id_cliente: number) {
    // Devuelve todos los servicios en aux_servicios a los que tiene acceso el cliente
    const path = `${this.apiURL}/getListaServiciosCliente/${id_cliente}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }



}
