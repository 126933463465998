import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// interfaces
import { IPedido } from 'src/app/interfaces/pedido';

// servicios
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ModalPedidoCabeceraService } from 'src/app/services/modal-pedido-cabecera.service';
import { DatosAuxService } from 'src/app/services/datos/datosAux.service';

// librerias
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-pedido-cabecera',
  templateUrl: './modal-pedido-cabecera.component.html',
  styleUrls: ['./modal-pedido-cabecera.component.scss']
})
export class ModalPedidoCabeceraComponent implements OnInit, OnDestroy {

  @Input() pedido?: IPedido = {};
  enviado: boolean = false;
  loading: boolean = false;

  public serviciosEnvioList: any[] = [];
  public paisesList: any[] = [];

  public formCabeza: FormGroup = new FormGroup({});
  cabeceraSubscription: Subscription | undefined;

  constructor(
    public modalPedidoCabeceraSrv: ModalPedidoCabeceraService,
    private notificacionesSrv: NotificacionesService,
    public datosAuxSrv: DatosAuxService,
    private notificSrv: NotificacionesService,
    private fb: FormBuilder) { }

  ngOnDestroy(): void {
    this.cabeceraSubscription?.unsubscribe();
  }

  ngOnInit(): void {

    this.formCabeza = this.fb.group({
      id: ['', Validators.required],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      poblacion: ['', Validators.required],
      cp: ['', Validators.required],
      telefono: [''],
      email: [],
      pais_iso: ['', Validators.required],
      cod_servicio: ['', Validators.required],
    });

    this.getListaPaises();
    this.getListaServicios();
    this.cargaPedido();

  }


  cerrarModal() {
    this.modalPedidoCabeceraSrv.pedido = undefined;
    this.modalPedidoCabeceraSrv.cerrarModal();
  }

  cerrarModalClickOutside(event: MouseEvent) {
    // Cerrar modal al hacer click fuera del modal  
    if ((event.target as HTMLElement).classList.contains('modal')) {
      // this.cerrarModal();
    }
  }


  cargaPedido() {
    this.cabeceraSubscription = this.modalPedidoCabeceraSrv.pedidoSubject.subscribe((pedido: IPedido) => {
      console.log('__cargaPedido():', pedido);

      if (!pedido) return;
      this.pedido = pedido;

      this.formCabeza.patchValue({
        id: pedido.id,
        nombre: pedido?.nombre,
        direccion: pedido?.direccion,
        poblacion: pedido?.poblacion,
        cp: pedido?.cp,
        telefono: pedido?.telefono,
        email: pedido?.email,
        pais_iso: pedido?.pais_iso,
        cod_servicio: pedido?.cod_servicio
      });
    });
  }


  getListaServicios() {
    this.loading = true
    this.datosAuxSrv.getListaServicios()
      .subscribe(resp => {
        this.loading = false
        console.log('__getListaServicios() resp', resp)

        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }
        this.serviciosEnvioList = resp.data;
      })
  }

  getListaPaises() {
    this.loading = true
    this.datosAuxSrv.getListaPaises()
      .subscribe(resp => {
        this.loading = false
        console.log('__getListaPaises() resp', resp)

        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }
        this.paisesList = resp.data;
      })
  }


  get f() {
    return this.formCabeza.controls;
  }


  enviar() {
    this.enviado = true;
    if (this.formCabeza.invalid) {
      console.log("ERRORES:", this.formCabeza.controls);
      console.groupCollapsed('Errores en el formulario');
      Object.keys(this.formCabeza.controls).forEach(key => {
        const control = this.formCabeza.get(key);
        if (control?.invalid) {
          console.log(` - ${key}:`, control.errors);
        }
      });
      console.groupEnd();
      return;
    }

    // Todo ok, enviar al BackEnd

    Swal.fire({
      title: 'Confirmar',
      text: "¿Seguro que deseas modificar la cabecera del pedido?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, modificar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.modalPedidoCabeceraSrv.updateCabecera(this.formCabeza.value)
          .subscribe(resp => {
            this.loading = false;
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              for (let controlName in resp.mensaje) {
                this.formCabeza.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
              }
            } else {
              this.notificacionesSrv.aviso('success', resp.mensaje);
              this.cerrarModal();
              return
            }
          })
      } else {
        this.notificacionesSrv.aviso('warning', 'Modificación de la cabaecera del pedido cancelada');
        this.cerrarModal();
        return
      }
    })
  }


  ///   AUX   ///


}
