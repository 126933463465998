import * as Const from '../../../shared/constants';

// Módulos
import { Component, ContentChild, OnInit, ViewChild } from '@angular/core';

// Interfaces y Modelos
import { ITransaccion } from 'src/app/interfaces/transacciones';
import { IFacturacion } from 'src/app/interfaces/facturacion';
import { ICliente } from 'src/app/interfaces/cliente';

// Servicios
import { TransaccionesService } from 'src/app/services/datos/transacciones.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';

// Librerías
import { ColumnMode } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-transacciones',
  templateUrl: './transacciones.component.html',
  styleUrls: ['./transacciones.component.scss']
})
export class TransaccionesComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  public loading: boolean = false;
  public enviado: boolean = false;
  public mostrarTabla: boolean = false;
  datosCabecera: IFacturacion = {}

  public clientesList: ICliente[] = [];
  cliSeleccionado: string = '';

  fechaDesde: string = '';
  fechaHasta: string = '';

  // Errores
  public errores: any = {}



  lista: ITransaccion[] = [];

  rows: any = [];
  temp: any[] = [];

  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  opcionesList: {
    id_cliente?: number,
    id_almacen?: number,
    id_articulo?: number,
    id_ubicacion?: number
  } = {}

  ColumnMode = ColumnMode;

  @ViewChild(TransaccionesComponent) table: TransaccionesComponent | any;

  constructor(
    private usuarioSrv: UsuarioService,
    private transaccionesSrv: TransaccionesService,
    private notificacionesSrv: NotificacionesService,
    private clientesSrv: ClientesService,

  ) { }

  ngOnInit(): void {

    const currentDate = new Date();

    // Primer día del mes
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.fechaDesde = this.formatDate(firstDay);

    // Último día del mes
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    this.fechaHasta = this.formatDate(lastDay);

    this.cargaClientes();
  }



  enviar() {
    this.enviado = true;

    // validar formulario
    this.errores = {}

    if (!this.cliSeleccionado) this.errores.cliSeleccionado = 'Requerido';
    if (!this.fechaDesde) this.errores.fechaDesde = 'Requerido';
    if (!this.fechaHasta) this.errores.fechaHasta = 'Requerido';

    if (this.fechaDesde && this.fechaHasta && new Date(this.fechaHasta) < new Date(this.fechaDesde)) {
      this.errores.fechaHasta = 'Debe ser mayor o igual a la fecha desde';
    }

    if (Object.keys(this.errores).length) {
      console.log('__enviar() ERRORES: ', Object.keys(this.errores).length);

      this.mostrarTabla = false;
      return
    }

    // Todo ok 

    this.datosCabecera = {
      'id_usuario': this.usuarioSrv.userdata.id!,
      'id_cliente': this.cliSeleccionado,
      'fechaDesde': this.fechaDesde,
      'fechaHasta': this.fechaHasta
    }

    console.log('__enviar() OK: ', this.datosCabecera); 
    this.cargaLista();

  }


  cargaClientes() {
    this.clientesSrv.getLista()
      .subscribe(resp => {
        this.loading = false;
        this.clientesList = resp.data;
      })
  }

  cargaLista() {
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);

    this.mostrarTabla = false;
    this.loading = true;
    this.transaccionesSrv.getListaFiltrada(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        this.mostrarTabla = true;
        this.lista = resp.data;
        console.log(resp)

        this.rows = this.lista;
        this.temp = [...this.lista];
      })
  }



  // Filtros

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
  }

  //  CRUD  // 

  deleteRow(row: ITransaccion) {
    Swal.fire({
      title: '¿Borrar transacción?',
      icon: 'question',
      html: `Eliminar transacción`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.transaccionesSrv.delete(row.id!)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto: any) => objeto.id !== row.id);
            }
          })
      }
    })
  }


  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }


  dblclickCampo(valor: any) {
    this.strBuscarTodo = String(valor);
    const txt = valor.toLowerCase() ?? '';
    this.buscaGlobal(txt);
  }


  buscaGlobal(txt: string) {
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.lista;
  }



  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }


  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscaGlobal('');
    const buscaTodoInput = document.getElementById('buscaTodo');
    buscaTodoInput?.focus();
  }


  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    return sum.toLocaleString('de-DE');
  }



  ///   AUX     ///

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }



  // Formatea una fecha como yyyy-mm-dd
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

}
