<!-- Formulario filtro -->
<div class="row">
    <div class="col-12 animated fadeIn fast">
        <div class="card border border-info rounded">
            <div class="card-body ">

                <form (ngSubmit)="enviar()">
                    <div class="row">

                        <!-- cliSeleccionado -->
                        <div class="col-4">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.cliSeleccionado }">
                                <div class="input-group-addon">Cliente</div>
                                <select class="form-control"
                                        [(ngModel)]="cliSeleccionado"
                                        name="cliSeleccionado"
                                        (ngModelChange)="onClienteSelect($event)">
                                    <option value=''>Selecciona...</option>
                                    <option *ngFor="let cli of clientesList"
                                            [value]=cli.id>
                                        {{cli.nombre}}
                                    </option>
                                </select>
                            </div>

                            <div *ngIf="enviado && errores.cliSeleccionado"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.cliSeleccionado }}</span>
                            </div>
                        </div>


                        <!-- fechaDesde -->
                        <div class="col-3">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.fechaDesde }">
                                <div class="input-group-addon">Desde</div>
                                <input class="form-control"
                                       type="date"
                                       [(ngModel)]="fechaDesde"
                                       name="fechaDesde"
                                       required>
                            </div>
                            <div *ngIf="enviado && errores.fechaDesde"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.fechaDesde }}</span>
                            </div>
                        </div>


                        <!-- fechaHasta -->
                        <div class="col-3">
                            <div class="input-group border border-dark rounded"
                                 [ngClass]="{ 'has-danger': errores.fechaHasta }">
                                <div class="input-group-addon">Hasta</div>
                                <input class="form-control"
                                       type="date"
                                       [(ngModel)]="fechaHasta"
                                       name="fechaHasta"
                                       required>
                            </div>
                            <div *ngIf="enviado && errores.fechaHasta"
                                 class="form-control-feedback">
                                <span class="text-danger">{{ errores.fechaHasta }}</span>
                            </div>
                        </div>


                        <!-- Submit -->
                        <div class="col-2">
                            <button [disabled]="loading"
                                    type="submit"
                                    class="ml-2 btn waves-effect waves-light btn-rounded btn-success">
                                <i class="fa fa-spinner fa-spin"
                                   *ngIf="loading"></i> Enviar</button>
                        </div>

                    </div><!-- row -->
                </form>

            </div>
        </div>
    </div>
</div> <!-- row -->




<!-- Tabla de datos -->
<div *ngIf="mostrarTabla"
     class="row">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">

                <!-- cabecera -->
                <div class="row mb-2">

                    <div class="col-6">

                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   id="buscaTodo"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   [(ngModel)]="strBuscarTodo"
                                   (keyup)="buscarTodo($event)" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBusca()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-3">
                    </div>

                    <div class="col-3">
                        <form>

                            <div class="row float-right">
                                <div class="col-sm-8">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group float-right">
                                        <div class="btn-group">

                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>


                <div class="rounded border">
                    <!-- datatable -->
                    <ngx-datatable class="bootstrap"
                                   [rows]="rows"
                                   [limit]="rowsLimit"
                                   [loadingIndicator]="loading"
                                   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [rowHeight]="'auto'"
                                   [messages]="messages"
                                   [reorderable]="true"
                                   [summaryRow]="true"
                                   [summaryHeight]="55"
                                   [summaryPosition]="summaryPosition">



                        <!-- id -->
                        <ngx-datatable-column prop='id'
                                              name="Id"
                                              [width]="70"
                                              [maxWidth]="70"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- fecha -->
                        <ngx-datatable-column name="fecha"
                                              prop="fecha"
                                              [width]="150"
                                              [maxWidth]="160">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- clienteNombre -->
                        <ngx-datatable-column prop='clienteNombre'
                                              name="Cliente"
                                              [width]="100"
                                              [maxWidth]="140">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- articuloSku -->
                        <ngx-datatable-column prop='articuloSku'
                                              name="Sku"
                                              [width]="100"
                                              [maxWidth]="150">
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                    <span *ngIf="row.articuloSwDefectuoso == Const.DEFECTUOSO"
                                          class="text-warning ml-12">
                                        <i title="Defectuoso"
                                           class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                    </span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- articuloEan -->
                        <ngx-datatable-column prop='articuloEan'
                                              name="Ean"
                                              [width]="100"
                                              [maxWidth]="145">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- ubiCodigo -->
                        <ngx-datatable-column prop='ubiCodigo'
                                              name="Ubicación"
                                              [width]="160"
                                              [maxWidth]="160">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     style="font-family: 'Verdana', monospace; letter-spacing: 0.2px;"
                                     [innerHTML]="resaltarTexto(value | mask: '00.00.00.00.00.00', textoDestacado | mask: '00.00.00.00.00.00')"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- cantidad -->
                        <ngx-datatable-column prop='cantidad'
                                              name="Cant."
                                              title="Cantidad"
                                              [width]="50"
                                              [maxWidth]="80"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas"
                                              [resizeable]="false">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{ value | number: '0.0-0':'es'}}
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- motivo -->
                        <ngx-datatable-column prop='motivo'
                                              name="Motivo"
                                              [width]="200"
                                              [maxWidth]="300">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- descripcion -->
                        <ngx-datatable-column prop='descripcion'
                                              name="Descripción"
                                              [width]="100"
                                              [maxWidth]="300">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- swFacturable -->
                        <ngx-datatable-column prop='swFacturable'
                                              name="Fact."
                                              [width]="50"
                                              [maxWidth]="60"
                                              [title]="'Facturable'">
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div title="Facturable"
                                     [innerHTML]="value | iconCheck"></div>
                            </ng-template>
                        </ngx-datatable-column>



                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>

</div>