// Módulos
import { Component, OnInit, ViewChild } from '@angular/core';

// Interfaces y Modelos
import { IAlmacen } from 'src/app/interfaces/almacenes';

// Servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// Librerías
import { ColumnMode } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { IUbiTipo } from 'src/app/interfaces/ubicacion';
import { UbiTiposService } from 'src/app/services/datos/ubi_tipos.service';

@Component({
  selector: 'app-ubi-tipos',
  templateUrl: './ubi-tipos.component.html',
  styleUrls: ['./ubi-tipos.component.scss']
})
export class UbiTiposComponent implements OnInit {
  public lista: IUbiTipo[] = [];
  public rows: any = [];
  public temp: any[] = [];

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  textoDestacado: string = '';
  loading = false;


  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  ColumnMode = ColumnMode;
  @ViewChild(UbiTiposComponent) table: UbiTiposComponent | any;

  constructor(
    private ubiTiposSrv: UbiTiposService,
    private notificacionesSrv: NotificacionesService,

  ) { }

  ngOnInit(): void {
    this.cargaLista();
  }

  cargaLista() {
    this.loading = true;
    this.ubiTiposSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.lista = respuesta.data;

        this.rows = this.lista;
        this.temp = [...this.lista];
      })
  }

  //  CRUD  // 

  deleteRow(row: any) {
    Swal.fire({
      title: '¿Borrar este tipo de ubicación?',
      icon: 'question',
      html: `Eliminar <b>${row.nombre}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.ubiTiposSrv.delete(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto: any) => objeto.id !== row.id);
            }
          })
      }
    })
  }



  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toString().toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }


  resaltarTexto(value: string | number, textoDestacado: string): string {
    if (!value || !textoDestacado) return `${value}`;

    const valueStr = value.toString(); // Convertir a cadena
    const regex = new RegExp(textoDestacado, 'gi');
    return valueStr.replace(regex, (match) => `<span class="resaltado">${match}</span>`);
  }



  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }


}
