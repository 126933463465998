import { Component, OnInit } from '@angular/core';
import { InfoService } from 'src/app/services/info.service';
import { VERSION } from '@angular/core';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  loading: boolean = false;
  info: string = '';
  angularVersion = VERSION.full; // Recupera la versión completa de Angular

  constructor(private infoSrv: InfoService) { }

  ngOnInit(): void {
    this.loading = true;
    this.infoSrv.getInfo().subscribe(resp => {
      this.loading = false;
      this.info = resp;
    });
  }

}
