<h1>Versión de Angular: {{ angularVersion }}</h1>
<hr>

<div *ngIf="loading">
    <div class="text-center">
        <div class="spinner-border"
             role="status">
            <span class="sr-only">Cagando...</span>
        </div>
    </div>
</div>

<div [innerHTML]="info"></div>