<div class="row animated fadeIn fast">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body">

                <!-- cabecera -->
                <div class="row mb-2">

                    <div class="col-6">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   id="buscaTodo"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   [(ngModel)]="strBuscarTodo"
                                   (keyup)="buscarTodo($event)" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBusca()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                            <select class="form-control"
                                    [(ngModel)]="cliSeleccionado"
                                    (ngModelChange)="onClienteSelect($event)">
                                <option value=''>Todos los clientes</option>
                                <option *ngFor="let cli of clientesList"
                                        [value]=cli.id>
                                    {{cli.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-3">
                        <form>

                            <div class="row float-right">
                                <div class="col-sm-6">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group float-right">
                                        <div class="btn-group">
                                            <button type="button"
                                                    [routerLink]="'/panel/articulo_edit/new'"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </button>
                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <!-- datatable -->
                    <ngx-datatable class='bootstrap'
                                   [columnMode]="ColumnMode.flex"
                                   [loadingIndicator]="loading"
                                   [headerHeight]="70"
                                   [footerHeight]="50"
                                   [rowHeight]="50"
                                   [limit]="rowsLimit"
                                   [rows]='rows'
                                   [messages]="messages"
                                   [footerHeight]="50"
                                   [summaryRow]="true"
                                   [summaryHeight]="55"
                                   [summaryPosition]="summaryPosition">




                        <!-- id -->
                        <ngx-datatable-column name="id"
                                              [flexGrow]="10">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header noSearch"
                                     (click)="sort()">
                                    <h6 class="title link">id</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- recogida_cod -->
                        <ngx-datatable-column name="Recogida"
                                              prop='recogida_cod'
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'recogida_cod')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Recogida</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     title="{{row.clienteNombre}}">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>


                        <!-- username -->
                        <ngx-datatable-column name="Asignado a"
                                              prop='username'
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'username')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Asignado a</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="{{row.usr_nombre}}">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>





                        <!-- sku -->
                        <ngx-datatable-column name="sku"
                                              pre="sku"
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'sku')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">SKU</h6>
                                </div>
                            </ng-template>

                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                    <span *ngIf="row.swDefectuoso == Const.DEFECTUOSO"
                                          class="text-warning ml-12">
                                        <i title="Defectuoso"
                                           class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                    </span>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>



                        <!-- ean -->
                        <ngx-datatable-column name="ean"
                                              pre="ean"
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'ean')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">Ean</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- descripcion -->
                        <ngx-datatable-column name="descripcion"
                                              pre="descripcion"
                                              [flexGrow]="30">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'descripcion')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">Descripción</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(limitarYAgregarPuntos(value, 20), textoDestacado)"
                                     [title]="value"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- ubicacion -->
                        <ngx-datatable-column name="ubicacion"
                                              pre="Ubicación"
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'ubicacion')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Ubicación</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     class="monospace"
                                     [innerHTML]="resaltarTexto(value | mask: '00.00.00.00.00.00', textoDestacado | mask: '00.00.00.00.00.00')"></div>
                            </ng-template>
                        </ngx-datatable-column>





                        <!-- cant_reserva -->
                        <ngx-datatable-column name="cant_reserva"
                                              prop="cant_reserva"
                                              [flexGrow]="10"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cant_reserva')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link"
                                        title="Cantidad reservada">Res.</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>{{ value | number: '0.0-0':'es' }}</ng-template>
                        </ngx-datatable-column>






                        <!-- cant_recogida -->
                        <ngx-datatable-column name="cant_recogida"
                                              prop="cant_recogida"
                                              [flexGrow]="10"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cant_recogida')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link"
                                        title="Cantidad reservada">Recogida</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>{{ value | number: '0.0-0':'es' }}</ng-template>
                        </ngx-datatable-column>





                        <!-- cant_inicial -->
                        <ngx-datatable-column name="cant_inicial"
                                              prop="cant_inicial"
                                              [flexGrow]="10"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cant_inicial')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link"
                                        title="Cantidad reservada">Inicial</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>{{ value | number: '0.0-0':'es' }}</ng-template>
                        </ngx-datatable-column>






                        <!-- saltar -->
                        <ngx-datatable-column name="saltar"
                                              pre="saltar"
                                              [flexGrow]="30">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'saltar')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="title link">Incidencia</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div [innerHTML]="resaltarTexto(limitarYAgregarPuntos(value, 20), textoDestacado)"
                                     [title]="value"></div>
                            </ng-template>
                        </ngx-datatable-column>






                        <!-- añadir / editar / borrar -->
                        <ngx-datatable-column [flexGrow]="10"
                                              [sortable]="false"
                                              [draggable]="false"
                                              [resizeable]="false">

                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>

                                <a (click)="goCampanya(row)"
                                   class="link mr-2 pointer"
                                   title="Campaña">
                                    <i class="fa-regular fa-pen-to-square fa"></i>
                                </a>

                            </ng-template>


                        </ngx-datatable-column>

                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>

</div>