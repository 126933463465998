import * as Const from '../../shared/constants';

// módulos
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// interfaces
import { IPedido } from 'src/app/interfaces/pedido';
import { ICampanya } from 'src/app/interfaces/campanya';
import { ICliente } from 'src/app/interfaces/cliente';

// servicios
import { PedidosService } from 'src/app/services/datos/pedidos.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerías
import FuncLib from 'src/app/providers/funcionesLib';
import { ModalFactSalidaService } from 'src/app/services/modal-fact-salida.service';
import { Subscription } from 'rxjs';
import { ICajetines } from 'src/app/interfaces/cajetines';


@Component({
  selector: 'app-pedidos-packaging',
  templateUrl: './pedidos-packaging.component.html',
  styleUrls: ['./pedidos-packaging.component.scss']
})
export class PedidosPackagingComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  // Control de cuando se cierra el modal
  modalClosedSubscription: Subscription | undefined;


  id_campanya: string = '';
  public campanya: ICampanya = {};
  public cliente: ICliente = {};

  pedidosList: IPedido[] = [];
  cajetinesList: ICajetines[] = [];

  public enviado: boolean = false;
  public loading: boolean = false;

  boxInput: string | null = null;
  boxInputError: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pedidosSrv: PedidosService,
    private notificacionesSrv: NotificacionesService,
    public modalFactSalidaSrv: ModalFactSalidaService,
  ) { }


  ngOnInit(): void {
    // Suscribirse a los parámetros de la url, si envían una id, cargar los pedidos de la campanya
    this.activatedRoute.params.subscribe(({ id_campanya }) => {
      this.id_campanya = id_campanya;
      this.cargaPedidosList();
    })

    this.modalClosedSubscription = this.modalFactSalidaSrv.modalClosed.subscribe(() => {
      // Suscribirse a modalClosed para saber cuando se cierra el modal y actualizar los datos de los pedidos
      console.log('El modal se ha cerrado con el pedido:');
      this.cargaPedidosList();
    });
  }

  cargaPedidosList() {

    this.loading = true;
    this.pedidosSrv.getPedidosSinEmbalar(this.id_campanya).subscribe(resp => {
      this.loading = false;

      console.log('__cargaPedidosList()', resp);

      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje)
        this.pedidosList = []
        return
      }
      this.pedidosList = resp.data;
      this.cliente = resp.totales.cliente;
      this.campanya = resp.totales.campanya;

      if (!this.pedidosList.length) {
        this.notificacionesSrv.aviso('success', `Todos los pedidos de la campaña ${this.campanya.camp_cod} con datos de embalaje`)
        this.router.navigate([`/pda`]);
      }

      this.getCajetinesCampanya();

    });

  }


  getCajetinesCampanya() {
    // Devuelve los cajetínes de una campaña
    this.loading = true;
    this.pedidosSrv.getCajetinesCampanya(this.campanya.camp_cod!).subscribe(resp => {
      this.loading = false;

      console.log('__getCajetinesCampanya()', resp);

      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje)
        this.cajetinesList = []
        return
      }
      this.cajetinesList = resp.data;
    });

  }




  todoEmbalado() {
    this.loading = true;
    this.pedidosSrv.setPedidosEmbalados(this.id_campanya).subscribe(resp => {
      this.loading = false;

      if (resp.error) {
        console.log(resp.mensaje);
        this.notificacionesSrv.aviso('error', resp.mensaje)
        this.cargaPedidosList()
        return
      }

      this.notificacionesSrv.aviso('success', `Todos los pedidos de la campaña ${this.campanya.camp_cod} marcados como embalaje finalilzado`)
      this.router.navigate([`/pda`]);
      return
    })

  }


  abrirModal(pedido: IPedido) {
    this.modalFactSalidaSrv.pedido = pedido
    console.log("__pedidos-packaging.component.ts abrirModal() pedido:", pedido);
    this.modalFactSalidaSrv.abrirModal();
  }


  validarBoxInput() {
    // Valida si existe el cajetín en la lista y abre el modal con el id_pedido que le corresponde
    this.boxInputError = false

    const regex = /^\d+\s+\d+$/; // Expresión regular para validar el formato "número + espacio + otro número"
    if (!regex.test(this.boxInput!)) {
      this.notificacionesSrv.aviso('error', 'Etiqueta inválida')
      this.boxInputError = true
      return
    }

    const partes: string[] = this.boxInput!.split(' ');
    const cajetinLabel = parseInt(partes[0]);

    const pedidoEncontrado = this.cajetinesList.find(item => item.cajetin == cajetinLabel);
    if (pedidoEncontrado) {
      console.log('__Pedido encontrado:', pedidoEncontrado);
      this.notificacionesSrv.aviso('success', `El cajetín ${cajetinLabel} es el pedido ${pedidoEncontrado.pedido_cli}`)
      this.abrirModal({ ...pedidoEncontrado, id: pedidoEncontrado.id_pedido })
      this.boxInput = null;
    } else {
      this.notificacionesSrv.aviso('error', `Esta campaña no tiene cajetín ${cajetinLabel}`)
      this.boxInputError = true
      return
    }

  }


  /// AUX ///


}
