import { Component, Input, OnInit } from '@angular/core';

// interfaces
import { IPedido, IPedidoDetall } from 'src/app/interfaces/pedido';

// servicios
import { RepartosService } from 'src/app/services/datos/repartos.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pedido-detall',
  templateUrl: './pedido-detall.component.html',
  styleUrls: ['./pedido-detall.component.scss']
})
export class PedidoDetallComponent implements OnInit {
  @Input() pedidoDetall: IPedido = {} // Detalle de un pedido  
  //@Input() articuloDetall: any = {} // Detalle de un artículo en un pedido

  loading: boolean = false;
  public articuloDetall: any = {};
  public articData: any = {};

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  constructor(
    private repartosSrv: RepartosService,
    private notificacionesSrv: NotificacionesService,
  ) { }

  ngOnInit(): void {  
    // this.leerTextoConVoz('Hola mundo!');
  }

  infoArticuloPedido(pedidoDetall: IPedidoDetall) {
    this.loading = true;
    this.articuloDetall = {};

    this.articData = pedidoDetall;
    const data = {
      id_pedido: this.articData.id_pedido,
      id_articulo: this.articData.id_articulo
    };
    this.repartosSrv.pedidoCajasRepartoDetall(data).subscribe(resp => {
      this.loading = false;
      console.log('__infoArticuloPedido() articData', this.articData);
      console.log('__infoArticuloPedido() resp', resp);

      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje || 'Error desconocido');
        return
      }
      this.articuloDetall = resp.data
    })

  }

  // AUX

  hasArticuloDetall(): boolean {
    return this.articuloDetall && Object.keys(this.articuloDetall).length > 0;
  }

  leerTextoConVoz(txtVoz: string): void {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(txtVoz);
    synth.speak(utterance);
  }



}
