import * as Const from '../../shared/constants';

import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable'

//Modelos e Interfaces 
import { ICliente } from 'src/app/interfaces/cliente';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IPreparaciones } from 'src/app/interfaces/preparaciones';

// Servicios  
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// Librerias
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';



@Component({
  selector: 'app-preparaciones-incidencias-list',
  templateUrl: './preparaciones-incidencias-list.component.html',
  styleUrls: ['./preparaciones-incidencias-list.component.scss']
})
export class PreparacionesIncidenciasListComponent implements OnInit {

  // Usar constantes en el template
  Const = Const

  loading: boolean = false;
  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  prepList: IPreparaciones[] = [];
  clientesList: ICliente[] = [];

  rows: IPreparaciones[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;

  opcionesList: IListaOpciones = {}
  cliSeleccionado: string = '';


  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);


  @ViewChild(PreparacionesIncidenciasListComponent) table: PreparacionesIncidenciasListComponent | any;

  constructor(
    public preparacionesSrv: PreparacionesService,
    private notificacionesSrv: NotificacionesService,
    private clientesSrv: ClientesService
  ) { }

  ngOnInit(): void {
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })

    this.getListaPrep();
  }

  getListaPrep() {
    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);

    this.loading = true;
    this.preparacionesSrv.getListaFiltrada(this.opcionesList)
      .subscribe(resp => {
        console.log(resp);
        this.loading = false;
        this.prepList = resp.data;
        this.rows = this.prepList;
        this.temp = [...this.prepList];
      })

  }





  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.prepList;
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.prepList;
  }



  dblclickCampo(valor: any) {
    this.strBuscarTodo = String(valor);
    const txt = valor.toLowerCase() ?? '';
    this.buscaGlobal(txt);
  }


  buscaGlobal(txt: string) {
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.prepList;
  }


  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }

  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscaGlobal('');
    const buscaTodoInput = document.getElementById('buscaTodo');
    buscaTodoInput?.focus();
  }

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }


  // Filtros

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.getListaPrep();
  }


  // AUX

  goCampanya(row: any) {
    alert("__goCampanya: " + JSON.stringify(row.campanya));
  }


  limitarYAgregarPuntos(value: string, maxLength: number): string {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    } else {
      return value;
    }
  }



  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');

  }

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }


}
