import * as Constantes from '../../../../shared/constants';

// Componentes
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable'

//Modelos e Interfaces
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { ICliente } from 'src/app/interfaces/cliente';
import { IListaOpciones } from 'src/app/interfaces/lista-opciones';
import { IPedido, IPedidoDetall } from 'src/app/interfaces/pedido';
import { MCampanyaPreparacionEstadosList, MPedidosEstadosList } from 'src/app/models/estadosList';

// Servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { DatosAuxService } from 'src/app/services/datos/datosAux.service';
import { PedidosService } from 'src/app/services/datos/pedidos.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ModalPedidoCabeceraService } from './../../../../services/modal-pedido-cabecera.service';


// Librerias y entornos
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cliente-pedidos-lista',
  templateUrl: './cliente-pedidos-lista.component.html',
  styleUrls: ['./cliente-pedidos-lista.component.scss']
})
export class ClientePedidosListaComponent implements OnInit {

  // Usar las constantes en el template
  Constantes = Constantes

  loading: boolean = false;
  public fotoDir: string = environment.fotoDir;

  textoDestacado: string = '';
  public strBuscarTodo: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }

  public lista: IPedido[] = [];   // lista de pedidos

  almacenesList: IAlmacen[] = [];
  clientesList: ICliente[] = [];
  serviciosEnvioList: any[] = [];
  public estadosPreparacionList: any[] = MCampanyaPreparacionEstadosList;
  public pedidosEstadosList: any[] = MPedidosEstadosList;

  rows: any[] = [];
  temp: any[] = [];

  pedidoSelected: IPedido = {};
  pedidoDetall: IPedidoDetall = {};

  columns = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  opcionesList: IListaOpciones = {}

  almSeleccionado: string = '';
  cliSeleccionado: string = '';
  estadoPedidoSeleccionado: string = '';
  cod_servicioSeleccionado: string = '';

  // Control de cuando se cierra el modal
  modalClosedSubscription: Subscription | undefined;

  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  @ViewChild(ClientePedidosListaComponent) table: ClientePedidosListaComponent | any;

  constructor(
    public pedidosSrv: PedidosService,
    public almacenesSrv: AlmacenesService,
    public clientesSrv: ClientesService,
    public userSrv: UsuarioService,
    public datosAuxSrv: DatosAuxService,
    private notificSrv: NotificacionesService,
    public modalPedidosCabeceraSrv: ModalPedidoCabeceraService
  ) {
  }


  ngOnInit(): void {
    this.getAlmaceneLists();
    this.getClientesList();
    this.getListaServicios();

    this.cargaLista();


    this.modalClosedSubscription = this.modalPedidosCabeceraSrv.modalClosed.subscribe(() => {
      // Suscribirse a modalClosed para saber cuando se cierra el modal y actualizar los datos de los pedidos
      console.log('_El modal se ha cerrado con el pedido:', this.pedidoSelected);
      this.cargaLista();
      this.infoClickPedido(this.pedidoSelected);
    });

  }



  cargaLista() {

    this.opcionesList.id_cliente = parseInt(this.cliSeleccionado);
    this.opcionesList.estado = parseInt(this.estadoPedidoSeleccionado);
    this.opcionesList.cod_servicio = this.cod_servicioSeleccionado;

    this.loading = true;
    this.pedidosSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        this.lista = resp.data;

        console.log(' __cargaLista() opcionesList', this.opcionesList)
        console.log(' __cargaLista()', this.lista)

        this.rows = this.lista;
        this.temp = [...this.lista];

        if (!this.pedidoSelected) this.pedidoSelected = this.lista[0];
      })
  }

  getAlmaceneLists() {
    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })
  }

  getClientesList() {
    this.clientesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.clientesList = respuesta.data;
      })
  }



  getListaServicios() {
    this.loading = true
    this.datosAuxSrv.getListaServicios()
      .subscribe(resp => {
        this.loading = false
        console.log('__getListaServicios() resp', resp)

        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }
        this.serviciosEnvioList = resp.data;
      })
  }

  // Búscar y filtrar

  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.lista;

    this.pedidoSelected = this.rows[0];
  }

  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  /// Búsqueda Global /// 

  buscarTodo() {
    const txt = this.strBuscarTodo?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
    this.pedidoSelected = this.rows[0];
  }

  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }


  limpiaBusca() {
    this.strBuscarTodo = '';
    this.buscarTodo();
    const buscaTodoInput = document.getElementById('buscaTodo');
    buscaTodoInput?.focus();
  }




  // busquedas parciales

  buscaEstado(evento: any) {
    const txt: string = evento.value;
    this.filtro(txt, 'estado');
  }

  buscaServicio(evento: any) {
    const txt: string = evento.value;
    this.filtro(txt, 'cod_servicio');
  }


  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  onAlmacenSelect(id: string) {
    this.almSeleccionado = id;
    this.cargaLista();
    this.pedidoDetall = {};
    this.strBuscarTodo = '';
  }

  onClienteSelect(id: string) {
    this.cliSeleccionado = id;
    this.cargaLista();
    this.pedidoDetall = {};
    this.strBuscarTodo = '';
  }


  onEstadoPedidoSelect(id: string) {
    this.estadoPedidoSeleccionado = id;
    this.cargaLista();
    this.pedidoDetall = {};
    this.strBuscarTodo = '';
  }

  onServicioSelect(cod_servicio: string) {
    this.cod_servicioSeleccionado = cod_servicio;
    this.cargaLista();
    this.pedidoDetall = {};
    this.strBuscarTodo = '';
  }



  //  CRUD  // 



  abrirModalCabeza(pedido: IPedido) {
    this.infoClickPedido(pedido);
    this.notificSrv.aviso('info', `editar pedido ${pedido.pedido_cli}`)
    this.modalPedidosCabeceraSrv.pedido = pedido
    this.modalPedidosCabeceraSrv.abrirModal();
  }


  pdfEtiquetaPedido(row: IPedido) {
    // Imprimir una etiqueta del pedido

    this.infoClickPedido(row);
    console.log('__imprimeEtiqueta() ', row);

    const data = {
      id_pedido: row.id,
      id_usuario: this.userSrv.userdata.id,
    };

    Swal.fire({
      title: 'Imprimir etiqueta',
      text: `¿Imprimir la etiqueta del pedido ${row.pedido_cli}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, imprimir',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.pedidosSrv.pedidoLabel(data)
          .subscribe(resp => {
            this.loading = false;
            console.log('__pedidoLabel', resp)
            this.notificSrv.aviso('info', resp.mensaje);
            if (resp.error) {
              this.notificSrv.aviso('error', resp.mensaje);
            }
          });
      }
    });

  }



  infoClickPedido(row: any) {
    // Info sobre un pedido

    this.pedidoSelected = row;
    console.log('__infoClickPedido click  this.pedidoSelected', this.pedidoSelected);

    // Destacar línea seleccionada en negrita
    this.rows.forEach((r: any) => {
      r.destacarLinea = false;
    });
    row.destacarLinea = true;

  }



  //  AUX //



  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }


  public sumaCeldas(cells: any[]): number {
    const filteredCells = cells.filter(cell => !!cell);
    const sum = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    return sum;
  }

  public nada(cells: any[]): string {
    return '';
  }



  descargaAlbaranClick(id_pedido: number) {
    console.log('__descargaAlbaranClick() id_pedido=', id_pedido);
    this.loading = true;
    this.pedidosSrv.downloadAlbaran(id_pedido)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje || 'Error desconocido');
          return
        }
        const urlPdf: string = environment.pdfDir + '/' + resp.data;
        window.open(urlPdf, '_blank');
      })
  }

}
