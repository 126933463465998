import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Interfaces / Modelos
import { ICliente } from 'src/app/interfaces/cliente';
import { ITarifa } from 'src/app/interfaces/tarifa';
import { IAlmacen } from 'src/app/interfaces/almacenes';
import { IUsuario } from 'src/app/interfaces/usuario';


// Servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { AlmacenesService } from './../../../../services/datos/almacenes.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { TarifasService } from 'src/app/services/datos/tarifas.service';
import { AuxDataService } from 'src/app/services/datos/auxData.service';

// Libs
import CustomVal from 'src/app/providers/CustomValidators';
import FuncLib from 'src/app/providers/funcionesLib';
import { ModalImagenService } from 'src/app/services/modal-imagen.service';
import { environment } from 'src/environments/environment';
import { Subscription, delay } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-cliente-edit',
  templateUrl: './cliente-edit.component.html',
  styleUrls: ['./cliente-edit.component.scss']
})
export class ClienteEditComponent implements OnInit {

  cliente: ICliente = {}
  paramId: string = '';

  public fotoDir: string = environment.fotoDir;
  public imgSubs: Subscription | undefined;

  tarifasList: ITarifa[] = [];
  usuariosList: IUsuario[] = [];
  almacenesList: IAlmacen[] = [];
  public listaPob: any[] = [];

  public cliForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;

  private funcLib: FuncLib = new FuncLib();
  public infoUbiDesde: string = '';
  public infoUbiHasta: string = '';

  constructor(
    private clienteSrv: ClientesService,
    public usuarioSrv: UsuarioService,
    private tarifasSrv: TarifasService,
    private almacenesSrv: AlmacenesService,
    private auxDataSrv: AuxDataService,
    public modalImagenSrv: ModalImagenService,
    private notificacionesService: NotificacionesService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,) { }

  public serviciosList: any[] = [];
  public codServicios_posibles: any[] = [];
  public codServicios_seleccionados: any[] = [];


  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del cliente
    this.activatedRoute.params.subscribe(({ id }) => {
      this.cliente.foto = '_noFoto.png';
      this.paramId = id;
      if (this.paramId != 'new') this.cargarCliente(id);
    })


    // Declara el fom reactivo
    this.cliForm = this.fb.group({
      nombre: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
      contacto: [''],
      id_tarifa: [''],
      conta_campanya: [''],
      direccion: ['', [Validators.minLength(6), Validators.maxLength(150)]],
      cp: [''],
      poblacion: ['', Validators.required],
      provincia: [''],
      email: ['', [Validators.required, CustomVal.ValidateEmail]],
      telefono: [''],
      url: ['', CustomVal.urlValidator],
      alm_por_defecto: ['', Validators.required],
      almacen_desde: ['', [Validators.minLength(12), Validators.maxLength(12)]],
      almacen_hasta: ['', [Validators.minLength(12), Validators.maxLength(12)]],
      lifo_fifo: [''],

      acceso_intranet: [null],
      agrupa_ubicaciones: [0],
      email_intranet: ['', [CustomVal.ValidateEmail]],
      codServicio_pred: [''],
      codServicios_posibles: ([]),

      conf_url_pedidos: [0],
      conf_url_pedidos_str: [''],
    })

    this.getTarifasList();
    this.getServiciosList();
    this.getAlmacenesList();


    // Nos suscribimos a la imagen, 
    // Si cambia la imagen, buscamos esa id en this.lista[]

    this.imgSubs = this.modalImagenSrv.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => {
        this.cliente.foto = img.guardado_como;
      });

  }

  cargarCliente(id: number) {
    this.loading = true;
    this.clienteSrv.getCliente(id)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesService.aviso('error', resp.mensaje);
          this, this.usuarioSrv.logOut();
        } else {
          this.cliente = resp.data;

          console.log('__cargarCliente() resp', resp);

          this.cliForm.patchValue({
            id_tarifa: this.cliente.id_tarifa,
            conta_campanya: this.cliente.conta_campanya,
            nombre: this.cliente.nombre,
            contacto: this.cliente.contacto,
            direccion: this.cliente.direccion,
            cp: this.cliente.cp,
            poblacion: this.cliente.poblacion,
            provincia: this.cliente.provincia,
            telefono: this.cliente.telefono,
            email: this.cliente.email,
            url: this.cliente.url,
            alm_por_defecto: this.cliente.alm_por_defecto,
            almacen_desde: this.cliente.almacen_desde,
            almacen_hasta: this.cliente.almacen_hasta,
            lifo_fifo: this.cliente.lifo_fifo,
            acceso_intranet: this.cliente.acceso_intranet,
            agrupa_ubicaciones: this.cliente.agrupa_ubicaciones,
            codServicio_pred: this.cliente.codServicio_pred,
            //  codServicios_posibles: this.cliente.codServicios_posibles,
            email_intranet: this.cliente.email_intranet,
            conf_url_pedidos: this.cliente.conf_url_pedidos,
            conf_url_pedidos_str: this.cliente.conf_url_pedidos_str
          })

          // Actualizar los check's de codServicios_seleccionados en el form
          if (this.cliente.codServicios_posibles) {
            const codServicios = JSON.parse(this.cliente.codServicios_posibles);
            this.codServicios_seleccionados = [...codServicios];

            this.cliForm.get('codServicios_posibles')?.setValue(this.codServicios_seleccionados);
          }

          this.buscaPob();
          this.cambiaUbiDesde();
          this.cambiaUbiHasta();
          this.getUsuariosList();

        }
      })
  }

  // Get lista de tarífas
  getTarifasList() {
    this.loading = true;
    this.tarifasSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.tarifasList = respuesta.data;
      })
  }


  // Get lista de usuaios de tipo 'cliente'
  getUsuariosList() {
    if (!this.cliente.id) return;
    this.loading = true;
    this.usuarioSrv.getUsrCliList(this.cliente.id!)
      .subscribe(respuesta => {
        this.loading = false;
        this.usuariosList = respuesta.data;
      })
  }

  // Get lista de tipos de servicio
  getServiciosList() {
    this.loading = true;
    this.auxDataSrv.getListaServicios()
      .subscribe(respuesta => {
        this.loading = false;
        this.serviciosList = respuesta.data;
      })
  }


  // Get lista de Almacenes
  getAlmacenesList() {
    this.loading = true;
    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })
  }


  enviar() {
    if (this.paramId === 'new') {
      this.newCli();
    } else {
      this.actualizaCli();
    }
    return
  }


  newCli() {
    this.enviado = true;

    if (this.cliForm.invalid) {
      console.log("ERRORES:", this.cliForm);
      return;
    }
    // Todo ok, enviar al BackEnd
    this.loading = true;
    this.clienteSrv.newCli(this.cliForm.value)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.cliForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesService.aviso('success', `Cliente ${resp.data.nombre} creado correctamente con el <b>id: ${resp.data.id}</b>`);
          this.router.navigateByUrl(`panel/clientes_lista`);
        }
      })
  }

  actualizaCli() {
    this.enviado = true;

    if (this.cliForm.invalid) {
      console.log("ERRORES:", this.cliForm);
      return;
    }

    // Todo ok, enviar al BackEnd

    const datos = {
      ...this.cliForm.value,
      id: this.cliente.id,
    };

    console.log('__actualizaCli() datos=', datos)

    this.loading = true;
    this.clienteSrv.actualizaCli(datos)
      .subscribe(resp => {
        this.loading = false;

        console.log('__actualizaCli()', resp)

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log("errores desde la API:", resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.cliForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesService.aviso('success', `Cliente ${this.cliForm.get('nombre')?.value} actualizado correctamente `);
          this.router.navigateByUrl(`panel/clientes_lista`);
        }
      })
  }


  cambiaUbiDesde() {
    const ubicacion = this.cliForm.get('almacen_desde')?.value;
    this.infoUbiDesde = this.funcLib.CodUbiDecript(ubicacion);
  }

  cambiaUbiHasta() {
    const ubicacion = this.cliForm.get('almacen_hasta')?.value;
    this.infoUbiHasta = this.funcLib.CodUbiDecript(ubicacion);
  }



  get f() {
    return this.cliForm.controls;
  }



  ///   AUX   ///


  buscaPob() {
    var cp = this.cliForm.value.cp;
    this.cliForm.patchValue({ poblacion: '', provincia: '' });
    this.listaPob = [];

    if (cp.length < 5) return;

    this.loading = true;
    this.auxDataSrv.getPoblaciones(cp)
      .subscribe((resp) => {
        this.loading = false;
        if (resp.error) {
          this.cliForm.controls['cp'].setErrors({ 'noExiste': true });
          return;
        }

        // Lista de Poblaciones
        var pobAux: string = '';
        for (let key of Object.keys(resp.data)) {
          if (typeof resp.data[key] === 'object' && resp.data[key].hasOwnProperty('Poblacion')) {
            pobAux = resp.data[key]['Poblacion'];
            if (!this.listaPob.includes(pobAux)) this.listaPob.push(pobAux);
          }
        }
        this.cliForm.patchValue({ poblacion: this.listaPob[0] });
        if (this.listaPob.includes(this.cliente.poblacion)) this.cliForm.patchValue({ poblacion: this.cliente.poblacion });

        // Provincia
        this.cliForm.patchValue({ provincia: resp.data.provincia.provincia });

      });

  }

  abrirModal(cliente: ICliente) {
    this.modalImagenSrv.abrirModal('clientes', cliente.id!.toString(), cliente.foto);
  }

  newUser() {
    if (this.cliForm.dirty) {

      const respuesta = Swal.fire({
        title: '¿Descartar cambios?',
        text: `Has hecho cambios en los datos del cliente, ¿Quieres descartarlos e ir a crear el nuevo usuario?`,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'No, seguir editando cliente',
        confirmButtonText: 'Si, descartar cambios',
      })

      respuesta.then((result) => {
        if (result.isConfirmed) {
          console.log('__newUser() confirma descartar cambios');
          this.router.navigateByUrl(`panel/usuario/new`, { state: this.getDefaultData() });
        }
        return
      });
    } else {
      this.router.navigateByUrl(`panel/usuario/new`, { state: this.getDefaultData() });
    }

  }

  private getDefaultData() {
    return {

      rol: 'cliente',
      rolIntranet: 'admin',
      id_cliente: this.cliente.id,
      alm_por_defecto: this.cliente.alm_por_defecto,
      volver_a: '/panel/cliente_edit/' + this.cliente.id,
    };
  }

  irAEditarUsuario(usuario: IUsuario) {
    const defaultData = {
      volver_a: '/panel/cliente_edit/' + this.cliente.id,
    }

    this.router.navigateByUrl(`panel/usuario/${usuario.id}`, { state: defaultData });
  }

  onCodServicioChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const value = checkbox.value;

    if (checkbox.checked) {
      this.codServicios_seleccionados.push(value);
    } else {
      this.codServicios_seleccionados = this.codServicios_seleccionados.filter(
        option => option !== value
      );
    }

    this.cliForm.get('codServicios_posibles')?.setValue(this.codServicios_seleccionados);
  }

}
